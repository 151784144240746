@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* For screens smaller than md */
  .bg-size-80-full {
    background-size: 80% 100%;
  }

  /* For md screens and larger */
  @screen md {
    .bg-size-50-full {
      background-size: 50% 100%;
    }
  }
}

/* Apply custom scrollbar styling */
body::-webkit-scrollbar {
  width: 5px;
  background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(94deg, #f04238 7.77%, #4d5fea 96.37%);
  border-radius: 3px;
}

html {
  scroll-behavior: smooth;
}


/* Apply Montserrat font to all h1 elements */
h1 {
  font-family: 'Poppins', sans-serif; 
  letter-spacing: 0.4px;
  font-weight: 700;
}

h2{
  font-family: 'Poppins', sans-serif; 
  letter-spacing: 0.2px;
  font-weight: 600;
}

/* Apply Lora font to all p elements */
p {
  font-family: 'Poppins', sans-serif;
  word-spacing: 1.5px;
  letter-spacing: 0.2px;
}

/* Apply Poppins font for poppins-700 and poppins-400 classname  */
.poppins-700 , a {
  font-family: 'Poppins', sans-serif; 
  letter-spacing: 0.2px;
  font-weight: 700;
}

.poppins-400 {
  font-family: 'Poppins', serif;
  word-spacing: 1.5px;
  letter-spacing: 0.2px;
  font-weight: 400;
}

/* Letter space and Word space for address elements*/
address > div {
  letter-spacing: 0.4px;
  word-spacing: 1.5px;
}